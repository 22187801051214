import React from "react";
import { Link } from "gatsby";
import { withAuthentication } from "../../hoc/withAuthentication";
import Main from "../../components/main";
import NotFoundPage from "../../components/notFoundPage";
import TextArea from "../../components/textArea";
import ContentArea from "../../components/contentArea";
import Consts from "../../config/consts";
import logo_pattern from "../../images/logo_pattern.png";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED, Consts.AUTH_LEVELS.UNAUTHENTICATED],
        redirectPage: "/",
    },
    class NotFound extends React.Component {
        // export default class Home extends React.Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {}

        render() {
            return (
                <Main title={"BMinted"} auth={this.props.auth} prices={this.props.prices} providers={this.props.providers} currentChain={this.props.currentChain} chains={this.props.chains}>
                    <NotFoundPage notFoundName={"Page"} />
                </Main>
            );
        }
    }
);
